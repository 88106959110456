<template>
  <div>
    <div class="title">{{ $t('projectSearch') }}</div>
    <div class="title-view">
      <div>{{ $t('screeningRequirements') }}</div>
      <div class="flex-row slider-view">
        <div>
          <div class="require">
            {{ $t('lessThan') }}
            <span>
              {{ period[0] }} {{ $t('day') }} - {{ period[1] }}
              {{ $t('day') }}
            </span>
          </div>
          <el-slider class="el-slider" v-model="period" :marks="marks1" :max="180" :min="30" range></el-slider>
        </div>
        <div>
          <div class="require">
            {{ $t('moreThan') }}
            <span>
              {{ amount[0] }} - {{ amount[1] }}
              {{ $t('millionDollar') }}
            </span>
          </div>
          <el-slider v-model="amount" :marks="marks2" :max="50" :step="1" range></el-slider>
        </div>
        <div>
          <div class="require">
            {{ $t('higherThan') }}
            <span>{{ rate[0] }}% - {{ rate[1] }}%</span>
          </div>
          <el-slider v-model="rate" :marks="marks3" :min="5" :max="25" range></el-slider>
        </div>
        <el-button class="btn-black" @click="clickSearch">
          {{
          $t('viewResults')
          }}
        </el-button>
      </div>
    </div>
    <el-table class="tab" :data="tableData" style="width: 1200px">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="tableEmpty"></div>
      </template>
      <el-table-column prop="username" :label="$t('corporateName')" width="180" align="center">
        <template v-slot="scope">
          {{
          scope.row.user && scope.row.user.username
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="interestRate"
        :label="$t('annualizedIncome')"
        width="100"
        align="center"
      >
        <template slot="header">
          <div>
            {{ $t('annualizedIncome') }}
            <span>*</span>
          </div>
        </template>
        <template v-slot="scope">
          {{
          scope.row.interestRate ? scope.row.interestRate + '%' : '0%'
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="borrowingCycle"
        :label="$t('termOfInvestment')+$t('unitI18n.DaysParentheses')"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="financingAmount"
        :label="$t('raisingAmount')"
        width="140"
        align="center"
      >
        <template v-slot="scope">
          {{scope.row.financingAmount | toThousandFilterTwo}}
        </template>
      </el-table-column>
      <el-table-column prop="investAmount" :label="$t('amountrRaised')" width="140" align="center">
        <template v-slot="scope">
          {{scope.row.investAmount | toThousandFilterTwo}}
        </template>
      </el-table-column>
      <el-table-column
        prop="deadlineFundRaising"
        :label="$t('deadlinefForFundAgain')"
        width="130"
        align="center"
      ></el-table-column>
      <el-table-column prop="boUsername" :label="$t('repaymentParty')" width="100" align="center">
        <template v-slot="scope">
          {{
          scope.row.boUser && scope.row.boUser.username ? scope.row.boUser.username : scope.row.user && scope.row.user.username
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="blockchainHash"
        :label="$t('blockNumber')"
        width="150"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column :label="$t('operation')" min-width="170" align="center">
        <template v-slot="scope">
          <div class="info-text">
            <el-button
              size="small"
              class="btn-black"
              @click="toInvest(scope.row)"
            >{{ $t('wantInvest') }}</el-button>
            <el-button size="small" class="btn-black" @click="dateils(scope)">{{ $t('details') }}</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="psStyle">{{ $t('toInvestPs') }}</div>
    <el-dialog
      :title="$t('wantInvest')"
      :visible.sync="dialogFormInvest"
      width="35%"
      :close-on-click-modal="false"
      @close="resetData"
    >
      <el-form ref="investForm" :model="ruleForm" :rules="rules">
        <el-form-item
          :label="`${$t('investmentAmountTitle')} (${$t('millionDollars')})`"
          prop="orderAmount"
        >
          <el-input
            v-model="ruleForm.orderAmount"
            :placeholder="$t('investmentAmountPla')"
            type="number"
            @input="getToken"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="tips-box">
        <p
          :class="Number(ruleForm.orderAmount) > 0 ? 'token-text' : 'token-null'"
        >{{ $t('investCreate.tokenNum') }}{{ this.tokenNum.toString() }}</p>
        <span>*1 {{ $t('investCreate.tokenDialogTitle') }} = 10000 {{ $t('dollar') }}</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormInvest = false" :disabled="isSubmit">{{ $t('btnI18n.Cancel') }}</el-button>
        <el-button
          class="btn-black"
          @click="affirmInvest"
          :disabled="isSubmit"
        >{{ $t('btnI18n.Confirms') }}</el-button>
      </div>
    </el-dialog>
    <AdPagination
      :currentPage="tablePageCP"
      :pageSize="tablePagePS"
      :pageTotal="tableTotal"
      @handlePage="handlePage"
    ></AdPagination>
  </div>
</template>

<script>
import { differenceInDays } from 'date-fns';
import AdPagination from '@/components/ad-pagination';
import { BigNumber } from 'bignumber.js';
export default {
  components: {
    AdPagination,
  },
  data() {
    return {
      isSubmit: false,
      btnList: [
        { title: this.$t('all'), select: true },
        { title: this.$t('profit'), select: false },
        { title: this.$t('investment'), select: false },
        { title: this.$t('withdrawal'), select: false },
      ],
      period: [30, 180],
      marks1: {
        30: '30',
        60: '60',
        90: '90',
        120: '120',
        150: '150',
        180: '180',
      },
      amount: [0, 50],
      marks2: {
        0: '0',
        10: '10',
        20: '20',
        30: '30',
        40: '40',
        50: '50',
      },
      rate: [5, 25],
      marks3: {
        5: '5%',
        10: '10%',
        15: '15%',
        20: '20%',
        25: '25%',
      },
      isSearch: false,
      primaryData: [],
      tableData: [],
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
      tableLoading: false,
      tableEmpty: this.$t('loading'),
      dialogFormInvest: false,
      ruleForm: {
        id: '',
        orderAmount: '',
      },
      rules: {
        id: [
          {
            required: true,
            message: this.$t('financeRequestIdPla'),
            trigger: 'blur',
          },
        ],
        orderAmount: [
          {
            required: true,
            message: this.$t('investmentAmountPla'),
            trigger: 'blur',
          },
        ],
      },
      tokenNum: '',
    };
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.getTableList();
    },
    getToken(val) {
      let valIndex = val.indexOf('.');
      if (valIndex !== -1) {
        val = this.ruleForm.orderAmount = val.substring(0, valIndex + 6);
      }
      const valNum = Number(val);
      const amountVal = new BigNumber(valNum);
      this.tokenNum = amountVal.times(0.0001);
    },
    getTableList() {
      this.tableLoading = true;
      const data = {
        page: this.tablePageCP,
        pageSize: this.tablePagePS,
      };
      if (this.isSearch) {
        data.period = this.period[0] + '-' + this.period[1];
        data.amount = (this.amount[0]) * this.$enums.UNIT_MILLIONS + '-' + (this.amount[1]) * this.$enums.UNIT_MILLIONS;
        data.rate = this.rate[0] + '-' + this.rate[1];
      }
      this.$axios
        .get('/v1/invest/financing-orders', { params: data })
        .then((response) => {
          if (response.code === 0 || response.code === '0') {
            this.tableData = response.data.rows || [];
            this.tableTotal = response.data.count || 0;
            this.tableLoading = false;
            this.tableEmpty = this.$t('tableEmpty');
            this.tableData.map((item) => {
              item.financingAmount =
                Number(item.financingAmount) / this.$enums.UNIT_MILLION;
              item.investAmount = item.investAmount
                ? Number(item.investAmount) / this.$enums.UNIT_MILLION
                : '0.00';
            });
          } else {
            this.tableLoading = false;
            this.tableEmpty = this.$t('tableEmpty');
            this.$message({ type: 'error', message: result.message });
          }
        })
        .catch((error) => {
          this.tableLoading = false;
          this.tableEmpty = this.$t('tableEmpty');
        });
    },
    toInvest(item) {
      this.ruleForm.id = item.id;
      this.ruleForm.orderAmount = null;
      this.dialogFormInvest = true;
    },
    dateils(scope) {
      this.$router.push({
        path: '/invest-find/pro-detail',
        query: { id: scope.row.id },
      });
    },
    affirmInvest() {
      this.isSubmit = true;
      let submit = true;
      if (
        !this.ruleForm.orderAmount ||
        this.ruleForm.orderAmount === '' ||
        this.ruleForm.orderAmount === undefined
      ) {
        submit = false;
        this.isSubmit = false;
        this.$message.error(this.$t('investmentAmountPla'));
        return false;
      }
      let info = JSON.parse(JSON.stringify(this.ruleForm));
      info.orderAmount = (
        info.orderAmount * this.$enums.UNIT_MILLION
      ).toString();
      if (submit) {
        this.$axios
          .post('/v1/invest/invest-order', info)
          .then((result) => {
            if (result.code === 0 || result.code === '0') {
              this.isSubmit = false;
              this.dialogFormInvest = false;
              this.$message({
                type: 'success',
                message: this.$t('investmentSuccess'),
              });
              this.$router.push(`/my-invest`);
            } else {
              this.isSubmit = false;
              this.dialogFormInvest = false;
              this.$message({ type: 'error', message: result.message });
            }
          })
          .catch((error) => {
            console.log('submit form error', error);
            this.isSubmit = false;
            this.dialogFormInvest = false;
          });
      }
    },
    clickSearch() {
      this.isSearch = true;
      this.getTableList();
    },
    resetData() {
      this.isSubmit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-view {
  // height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #979797;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px 30px 30px 30px;
  .slider-view {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .require {
      // max-height: 40px;
      width: 280px;
      margin-top: 10px;
      height: 44px;
      span {
        color: #81d8d0;
      }
    }
    .btn-black {
      margin-left: 50px;
    }
  }
  ::v-deep.el-slider {
    width: 280px;
  }
  ::v-deep.el-slider__bar {
    background-color: #81d8d0;
  }
  ::v-deep.el-slider__button {
    width: 10px;
    height: 10px;
    border: solid 2px #81d8d0;
    background: #81d8d0;
  }
  ::v-deep.el-slider__stop {
    border: solid 2px #81d8d0;
    margin-top: -2px;
  }
  ::v-deep.el-slider__marks-text {
    color: #353535;
    width: 50px;
    text-align: center;
  }
}

.tab {
  margin-top: 20px;
  // border: 1px solid #979797;
  // border-radius: 10px;
}
.el-table {
  .cell {
    word-break: normal;
  }
}
.psStyle {
  margin-top: 10px;
  color: #000;
  font-size: 14px;
}
.tips-box {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .token-text {
    font-size: 16px;
    font-family: ArialMT;
    color: #000000;
  }
  .token-null {
    visibility: hidden;
  }
}
</style>
